import {
  JSONValue,
  Participant,
  ParticipantType,
  User,
} from "@twilio/conversations";
import { participantsMap } from "../../conversations-objects";

import { ActionType } from "../action-types";
import { Action } from "../actions";

export type ReduxParticipant = {
  sid: string;
  attributes: JSONValue | any;
  identity: string | null;
  type: ParticipantType;
  lastReadMessageIndex: number | null;
  isOnline: boolean | null;
  friendlyName: string | null;
};

export type ParticipantUser = {
  participant: Participant;
  user: User;
};

export type ParticipantsType = Record<string, ReduxParticipant[]>;

const initialState: ParticipantsType = {};

const reduxifyParticipant = (pu: ParticipantUser): ReduxParticipant => ({
  sid: pu.participant.sid,
  attributes: pu.user.attributes,
  identity: pu.participant.identity,
  type: pu.participant.type,
  lastReadMessageIndex: pu.participant.lastReadMessageIndex,
  isOnline: pu.user.isOnline,
  friendlyName: pu.user.friendlyName,
});

const reducer = (
  state: ParticipantsType = initialState,
  action: Action
): ParticipantsType => {
  switch (action.type) {
    case ActionType.UPDATE_PARTICIPANTS:
      const { participants, sid } = action.payload;

      const filteredParticipants = participants.filter(
        (pu: ParticipantUser) =>
          pu.participant.identity !== localStorage.getItem("username")
      );
      for (const pu of filteredParticipants) {
        participantsMap.set(sid, pu.participant);
      }

      return Object.assign({}, state, {
        [sid]: filteredParticipants.map(reduxifyParticipant),
      });

    case ActionType.UPDATE_PARTICIPANT_STATUS:
      const participant = action.payload.participant;
      const channelId = action.payload.channelId;
      const isOnline = action.payload.isOnline;
      const newState = { ...state };
      const participantToUpdateIndex = state[channelId].findIndex(
        (p) => p.identity === participant.identity
      );
      if (participantToUpdateIndex >= 0) {
        const participantToUpdate = state[channelId][participantToUpdateIndex];
        participantToUpdate.isOnline = isOnline;
        newState[channelId][participantToUpdateIndex] = participantToUpdate;
      }

      return {
        ...newState,
      };

    default:
      return state;
  }
};

export default reducer;
