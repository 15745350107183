export enum ActionType {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  ADD_CONVERSATION = "ADD_CONVERSATION",
  ADD_ALL_CONVERSATIONS = "ADD_ALL_CONVERSATIONS",
  REMOVE_CONVERSATION = "REMOVE_CONVERSATION",
  UPDATE_CURRENT_CONVERSATION = "UPDATE_CURRENT_CONVERSATION",
  CONVERSATION_LAST_READ_INDEX = "CONVERSATION_LAST_READ_INDEX",
  ADD_MESSAGES = "ADD_MESSAGES",
  PUSH_MESSAGES = "PUSH_MESSAGES",
  REMOVE_MESSAGES = "REMOVE_MESSAGES",
  UPDATE_LOADING_STATE = "update loading state",
  UPDATE_PARTICIPANTS = "UPDATE_PARTICIPANTS",
  UPDATE_PARTICIPANT_STATUS = "UPDATE_PARTICIPANT_STATUS",
  UPDATE_UNREAD_MESSAGES = "UPDATE_UNREAD_MESSAGES",
  UPDATE_CONVERSATION = "UPDATE_CONVERSATION",
  ADD_ATTACHMENT = "ADD_ATTACHMENT",
  CLEAR_ATTACHMENTS = "CLEAR_ATTACHMENTS",
  TYPING_STARTED = "TYPING_STARTED",
  TYPING_ENDED = "TYPING_ENDED",
  ADD_NOTIFICATIONS = "ADD_NOTIFICATIONS",
  REMOVE_NOTIFICATIONS = "REMOVE_NOTIFICATIONS",
  SET_CURRENT_CONVERSATION_INDEX = "SET_CURRENT_CONVERSATION_INDEX",
  SORT_CONVERSATIONS = "SORT_CONVERSATIONS",
  INCREMENT_UNREAD_COUNT = "INCREMENT_UNREAD_COUNT",
  GET_SUBSCRIPTION_INFO = "GET_SUBSCRIPTION_INFO",
  GET_SUBSCRIPTION_INFO_SUCCESS = "GET_SUBSCRIPTION_INFO_SUCCESS",
  GET_SUBSCRIPTION_INFO_FAILURE = "GET_SUBSCRIPTION_INFO_FAILURE",
}
