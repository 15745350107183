import { Action, combineReducers } from "redux";

import tokenReducer from "./tokenReducer";
import convoReducer, { ReduxConversation } from "./convoReducer";
import sidReducer from "./currentConvoReducer";
import messageReducer, { ChatMessagesState } from "./messageListReducer";
import loadingReducer from "./loadingReducer";
import participantReducer, { ParticipantsType } from "./participantsReducer";
import unreadMessagesReducer, {
  UnreadMessagesState,
} from "./unreadMessagesReducer";
import attachmentsReducer, { AttachmentsState } from "./attachmentsReducer";
import { ActionType } from "../action-types";
import typingDataReducer, { TypingDataState } from "./typingDataReducer";
import lastReadIndexReducer from "./lastReadIndexReducer";
import notificationsReducer, {
  NotificationsType,
} from "./notificationsReducer";
import currentConversationIndexReducer from "./currentConversationIndexReducer";
import unreadCountReducer from "./unreadCountReducer";
import subscriptionInfoReducer from "./subscriptionInfoReducer";

export enum SubscriptionType {
  FREE = "FREE",
  SILVER = "SILVER",
  GOLD = "GOLD",
  PLATINUM = "PLATINUM",
}

export class SubscriptionInformation {
  id: string;
  price: number;
  type: SubscriptionType;
  subscriptionId: string;
  isActive: boolean;
  constructor(
    id: string,
    price: number,
    type: SubscriptionType,
    subscriptionId: string,
    isActive: boolean
  ) {
    this.id = id;
    this.price = price;
    this.type = type;
    this.subscriptionId = subscriptionId;
    this.isActive = isActive;
  }
}

export type AppState = {
  token: string;
  subscriptionInformation: SubscriptionInformation | null;
  convos: ReduxConversation[];
  sid: string;
  messages: ChatMessagesState;
  unreadMessages: UnreadMessagesState;
  loadingStatus: boolean;
  participants: ParticipantsType;
  attachments: AttachmentsState;
  typingData: TypingDataState;
  lastReadIndex: number;
  notifications: NotificationsType;
  currentConversationIndex: number;
  unreadConversationCount: {
    unreadConvos: { sid: string; amount: number }[];
    accumulator: number;
  };
};

export const initialState = {
  token: "",
  sid: "",
  messages: {},
  attachments: {},
  participants: {},
  convos: [],
  unreadMessages: {},
  loadingStatus: true,
  typingData: {},
  lastReadIndex: -1,
  notifications: [],
  currentConversationIndex: -1,
  unreadConversationCount: { unreadConvos: [], accumulator: 0 },
  subscriptionInformation: null,
} as AppState;

const reducers = (
  state: AppState | undefined,
  action: Action
): ReturnType<typeof appReducer> => {
  if (action.type === ActionType.LOGOUT) {
    localStorage.removeItem("username");
    localStorage.removeItem("password");
    return appReducer(initialState, action);
  }

  return appReducer(state, action);
};

const appReducer = combineReducers({
  token: tokenReducer,
  convos: convoReducer,
  sid: sidReducer,
  lastReadIndex: lastReadIndexReducer,
  messages: messageReducer,
  loadingStatus: loadingReducer,
  participants: participantReducer,
  unreadMessages: unreadMessagesReducer,
  attachments: attachmentsReducer,
  typingData: typingDataReducer,
  notifications: notificationsReducer,
  currentConversationIndex: currentConversationIndexReducer,
  unreadConversationCount: unreadCountReducer,
  subscriptionInformation: subscriptionInfoReducer,
});

export default reducers;
