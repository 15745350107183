import {
  Conversation,
  Message,
  Participant,
  Media,
  Client,
  Paginator,
} from "@twilio/conversations";

import {
  MessageStatus,
  ReduxMessage,
} from "./store/reducers/messageListReducer";
import {
  CONVERSATION_MESSAGES,
  CONVERSATION_PAGE_SIZE,
  PARTICIPANT_MESSAGES,
  UNEXPECTED_ERROR_MESSAGE,
} from "./constants";
import { NotificationsType } from "./store/reducers/notificationsReducer";
import { successNotification, unexpectedErrorNotification } from "./helpers";
import { getSdkMessageObject } from "./conversations-objects";
import {
  ParticipantUser,
  ReduxParticipant,
} from "./store/reducers/participantsReducer";
import { SetParticipantsType } from "./types";
import axios from "axios";
import { getBaseUrl } from "./enviroment";
import axiosInstance from "./auth.interceptor";

type ParticipantResponse = ReturnType<typeof Conversation.prototype.add>;
const apiUrl = `${getBaseUrl()}/api/conversation-emails`;

export async function updateEmailConversation(
  conversationId: string,
  userSenderId: string,
  userReceiverId: string,
  senderAccountType: string,
  receiverAccountType: string
): Promise<{ success: boolean }> {
  try {
    const response = await axiosInstance.post(
      "/conversation-emails/update-conversation",
      {
        conversationId,
        userSenderId,
        userReceiverId,
        senderAccountType,
        receiverAccountType,
      }
    );
    return response.data;
  } catch {
    return { success: false };
  }
}

export async function readConversation(
  conversationId: string,
  userSenderId: string,
  userReceiverId: string,
  senderAccountType: string,
  receiverAccountType: string
): Promise<{ success: boolean }> {
  try {
    const response = await axiosInstance.post(
      "/conversation-emails/read-conversation",
      {
        conversationId,
        userSenderId,
        userReceiverId,
        senderAccountType,
        receiverAccountType,
      }
    );
    return response.data;
  } catch {
    return { success: false };
  }
}

export async function addConversation(
  name: string,
  updateParticipants: SetParticipantsType,
  client?: Client,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<Conversation> {
  if (name.length > 0 && client !== undefined) {
    try {
      const conversation = await client.createConversation({
        friendlyName: name,
      });
      await conversation.join();

      const participants = await getConversationParticipants(conversation);
      updateParticipants(participants, conversation.sid);

      successNotification({
        message: CONVERSATION_MESSAGES.CREATED,
        addNotifications,
      });

      return conversation;
    } catch (e) {
      unexpectedErrorNotification(addNotifications);

      return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
  }
  return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
}

export async function getCompanySubscription(): Promise<any> {
  return axiosInstance.get("/company-subscriptions/me");
}

export async function checkCompanySubscription(
  userId: string
): Promise<{ hasFreeSubscription: boolean }> {
  let response = null;
  try {
    response = (
      await axiosInstance.get(`/worker-companies/chat/${userId}/check`)
    ).data;
    return response;
  } catch (e) {
    return Promise.reject(new Error("no_company_profile"));
  }
}

export async function startSupportChat(): Promise<{ conversationId: string }> {
  try {
    const response = await axiosInstance.get<{ conversationId: string }>(
      "/admin-support/start"
    );
    return response.data;
  } catch {
    return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
  }
}

export async function isCompanyApproved(userId: string): Promise<boolean> {
  try {
    const response = await axiosInstance.get(`/users/is-approved/${userId}`);
    return response.data.success;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function addParticipant(
  name: string,
  proxyName: string,
  chatParticipant: boolean,
  convo?: Conversation,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<ParticipantResponse> {
  if (convo === undefined) {
    return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
  }

  if (chatParticipant && name.length > 0) {
    try {
      const result = await convo.add(name);
      successNotification({
        message: PARTICIPANT_MESSAGES.ADDED,
        addNotifications,
      });
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  if (!chatParticipant && name.length > 0 && proxyName.length > 0) {
    try {
      const result = await convo.addNonChatParticipant(proxyName, name, {
        friendlyName: name,
      });
      successNotification({
        message: PARTICIPANT_MESSAGES.ADDED,
        addNotifications,
      });

      return result;
    } catch (e) {
      unexpectedErrorNotification(addNotifications);

      return Promise.reject(e);
    }
  }
  return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
}

export async function getToken(): Promise<any> {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params.token;
}

export async function getMessageStatus(
  message: ReduxMessage,
  channelParticipants: ReduxParticipant[]
): Promise<{
  [MessageStatus.Delivered]?: number;
  [MessageStatus.Read]?: number;
  [MessageStatus.Failed]?: number;
  [MessageStatus.Sending]?: number;
}> {
  const statuses = {
    [MessageStatus.Delivered]: 0,
    [MessageStatus.Read]: 0,
    [MessageStatus.Failed]: 0,
    [MessageStatus.Sending]: 0,
  };

  if (message.index === -1) {
    return Promise.resolve({
      ...statuses,
      [MessageStatus.Sending]: 1,
    });
  }

  channelParticipants.forEach((participant) => {
    if (
      participant.identity == localStorage.getItem("username") ||
      participant.type !== "chat"
    ) {
      return;
    }

    if (
      participant.lastReadMessageIndex &&
      participant.lastReadMessageIndex >= message.index
    ) {
      statuses[MessageStatus.Read] += 1;
    } else if (participant.lastReadMessageIndex !== -1) {
    }
  });

  if (message.aggregatedDeliveryReceipt) {
    const sdkMessage = getSdkMessageObject(message);
    const receipts = await sdkMessage.getDetailedDeliveryReceipts(); // paginated backend query every time
    receipts.forEach((receipt) => {
      if (receipt.status === "read") {
        statuses[MessageStatus.Read] += 1;
      }

      if (receipt.status === "delivered") {
        statuses[MessageStatus.Delivered] += 1;
      }

      if (receipt.status === "failed" || receipt.status === "undelivered") {
        statuses[MessageStatus.Failed] += 1;
      }

      if (receipt.status === "sent" || receipt.status === "queued") {
        statuses[MessageStatus.Sending] += 1;
      }
    });
  }

  return statuses;
}

export const getConversationParticipants = async (
  conversation: Conversation
): Promise<ParticipantUser[]> => {
  const participants = (await conversation.getParticipants()).filter(
    (p) => p.identity !== localStorage.getItem("username")
  );

  const participantUsers: ParticipantUser[] = [];
  for (const participant of participants) {
    const user = await participant.getUser();
    participantUsers.push({ participant, user });
  }
  return participantUsers;
};

export const removeParticipant = async (
  conversation: Conversation,
  participant: Participant,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<void> => {
  try {
    await conversation.removeParticipant(participant);
    successNotification({
      message: PARTICIPANT_MESSAGES.REMOVED,
      addNotifications,
    });
  } catch {
    unexpectedErrorNotification(addNotifications);
    return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getBlobFile = async (
  media: Media,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<Blob> => {
  try {
    const url = await getFileUrl(media);
    const response = await fetch(url);
    return response.blob();
  } catch (e) {
    unexpectedErrorNotification(addNotifications);
    return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getFileUrl = async (media: Media): Promise<string> => {
  return await media.getContentTemporaryUrl().then();
};

export const getMessages = async (
  conversation: Conversation
): Promise<Paginator<Message>> =>
  await conversation.getMessages(CONVERSATION_PAGE_SIZE);
