import { useState, useEffect, useMemo, useRef } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import {
  Message,
  Conversation,
  Participant,
  Client,
} from "@twilio/conversations";
import { Box } from "@twilio-paste/core";

import { actionCreators, AppState } from "../store";
import ConversationContainer from "./conversations/ConversationContainer";
import ConversationsContainer from "./conversations/ConversationsContainer";
import {
  AddMessagesType,
  SetParticipantsType,
  SetUnreadMessagesType,
} from "../types";
import {
  getConversationParticipants,
  getToken,
  updateEmailConversation,
} from "../api";
import useAppAlert from "../hooks/useAppAlerts";
import Notifications from "./Notifications";
import stylesheet from "../styles";
import { handlePromiseRejection } from "../helpers";
import { ActionType } from "../store/action-types";
import { setCurrentConversationIndex } from "../store/action-creators";

// import {
//   initFcmServiceWorker,
//   subscribeFcmNotifications,
//   showNotification,
// } from "../firebase-support";

async function loadUnreadMessagesCount(
  convo: Conversation,
  updateUnreadMessages: SetUnreadMessagesType
) {
  let count = 0;

  try {
    count =
      (await convo.getUnreadMessagesCount()) ??
      (await convo.getMessagesCount());
  } catch (e) {
    console.error("getUnreadMessagesCount threw an error", e);
  }

  updateUnreadMessages(convo.sid, count);
}

async function handleParticipantsUpdate(
  participant: Participant,
  updateParticipants: SetParticipantsType
) {
  const result = await getConversationParticipants(participant.conversation);
  updateParticipants(result, participant.conversation.sid);
  console.log("update participants");
}

async function getSubscribedConversations(
  client: Client
): Promise<Conversation[]> {
  let subscribedConversations = await client.getSubscribedConversations();
  let conversations = subscribedConversations.items;

  while (subscribedConversations.hasNextPage) {
    subscribedConversations = await subscribedConversations.nextPage();
    conversations = [...conversations, ...subscribedConversations.items];
  }

  return conversations;
}

const AppContainer: React.FC = () => {
  /* eslint-disable */
  const [client, setClient] = useState<Client>();
  const token = useSelector((state: AppState) => state.token);
  const conversations = useSelector((state: AppState) => state.convos);
  const participants = useSelector((state: AppState) => state.participants);
  const participantsRef = useRef(participants);
  const sid = useSelector((state: AppState) => state.sid);
  const sidRef = useRef("");
  const [alertsExist, AlertsView] = useAppAlert();
  sidRef.current = sid;
  const cooldown = useRef(true);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const convoIndex = useSelector(
    (state: AppState) => state.currentConversationIndex
  );
  const previousIndex = useRef(convoIndex);
  const convoRef = useRef(conversations);
  const unread$ = useSelector(
    (state: AppState) => state.unreadConversationCount
  );
  function handleWindowSizeChange() {
    const isMobile = window.innerWidth <= 768;
    setIsMobile(isMobile);
  }

  async function handleConversationSorting(
    author: string,
    convo: Conversation,
    convoIdx: number,
    idx: number
  ) {
    if (author !== localStorage.getItem("username")) {
      incrementUnreadCount(convo.sid, 1);
    }
    sortConversations(convo.sid);

    // when client sends a msg to one of their contacts
    if (author === localStorage.getItem("username")) {
      setCurrentConversationIndex(0);
      clearAttachments(convo.sid, "-1");
    }
    if (author !== localStorage.getItem("username") && idx >= 0) {
      if (idx > convoIdx) {
        setCurrentConversationIndex(previousIndex.current);
      } else if (idx < convoIdx) {
        setCurrentConversationIndex(previousIndex.current + 1);
      }
    }
  }

  async function handleConversationUpdate(
    author: string,
    currentUsername: string,
    senderAuthorType: string,
    message: any
  ) {
    if (author === currentUsername) {
      const fromUserId = message.author;
      const toUserId = (await message.conversation.getParticipants()).find(
        (p: Participant) => p.identity !== fromUserId
      )?.identity;

      const receiverAccountType =
        senderAuthorType === "company" ? "worker" : "company";
      if (senderAuthorType && fromUserId && toUserId) {
        await updateEmailConversation(
          message.conversation.sid,
          fromUserId,
          toUserId,
          senderAuthorType,
          receiverAccountType
        );
      }
    }
  }

  useEffect(() => {
    participantsRef.current = participants;
  }, [participants]);

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    setTimeout(() => {
      cooldown.current = false;
    }, 5000);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const username = localStorage.getItem("username");
  const password = localStorage.getItem("password");

  const dispatch = useDispatch();
  const {
    addMessages,
    updateLoadingState,
    updateParticipants,
    updateUnreadMessages,
    startTyping,
    endTyping,
    addConversation,
    addAllConversation,
    login,
    removeMessages,
    removeConversation,
    updateCurrentConversation,
    addNotifications,
    logout,
    clearAttachments,
    sortConversations,
    setCurrentConversationIndex,
    incrementUnreadCount,
    getSubscriptionInfo,
    updateParticipantStatus,
  } = bindActionCreators(actionCreators, dispatch);

  const updateTypingIndicator = async (
    participant: Participant,
    sid: string,
    callback: (sid: string, user: string) => void
  ) => {
    const { identity } = participant;
    if (identity === localStorage.getItem("username")) {
      return;
    }
    const user = await participant.getUser();
    callback(sid, user.friendlyName || "");
  };

  useEffect(() => {
    const client = new Client(token);
    setClient(client);

    const fcmInit = async () => {
      // await initFcmServiceWorker();
      // await subscribeFcmNotifications(client);
    };

    fcmInit().catch(() => {
      console.error(
        "FCM initialization failed: no push notifications will be available"
      );
    });

    client.on("conversationJoined", async (conversation) => {
      const { attributes, identity, isOnline, isNotifiable, isSubscribed } =
        client.user;

      const count = (await conversation.getUnreadMessagesCount()) as number;
      addConversation(conversation);
      incrementUnreadCount(conversation.sid, count);

      if (!cooldown.current) updateCurrentConversation(conversation.sid);
      conversation.on("typingStarted", (participant) => {
        handlePromiseRejection(
          () =>
            updateTypingIndicator(participant, conversation.sid, startTyping),
          addNotifications
        );
      });

      conversation.on("typingEnded", (participant) => {
        handlePromiseRejection(
          () => updateTypingIndicator(participant, conversation.sid, endTyping),
          addNotifications
        );
      });

      handlePromiseRejection(async () => {
        if (conversation.status === "joined") {
          const result = await getConversationParticipants(conversation);
          updateParticipants(result, conversation.sid);
          const messages = await conversation.getMessages();
          addMessages(conversation.sid, messages.items);
          loadUnreadMessagesCount(conversation, updateUnreadMessages);
        }
      }, addNotifications);
    });

    client.on("conversationRemoved", (conversation: Conversation) => {
      updateCurrentConversation("");
      handlePromiseRejection(() => {
        removeConversation(conversation.sid);
        updateParticipants([], conversation.sid);
      }, addNotifications);
    });
    client.on("messageAdded", async (message: Message) => {
      console.log("messageAdded");
      addMessage(message, addMessages, updateUnreadMessages);
      const currentUsername = localStorage.getItem("username");
      const author = message.author;
      const senderAuthorType = localStorage.getItem("type");

      if (author && senderAuthorType && currentUsername) {
        await handleConversationUpdate(
          author,
          currentUsername,
          senderAuthorType,
          message
        );
      }

      const convo = message.conversation;
      const idx = previousIndex.current;
      const convoIdx = convoRef.current.findIndex((c) => c.sid === convo.sid);
      await handleConversationSorting(author ?? "", convo, convoIdx, idx);
    });
    client.on("participantLeft", (participant) => {
      handlePromiseRejection(
        () => handleParticipantsUpdate(participant, updateParticipants),
        addNotifications
      );
    });
    client.on("participantUpdated", (event) => {
      handlePromiseRejection(
        () => handleParticipantsUpdate(event.participant, updateParticipants),
        addNotifications
      );
    });

    client.on("userUpdated", (userInstance) => {
      if (userInstance.updateReasons.includes("reachabilityOnline")) {
        setTimeout(() => {
          const keys = Object.keys(participantsRef.current);

          const userIdentity = userInstance.user.identity;
          const isOnline = userInstance.user.isOnline ?? false;
          keys.forEach((key) => {
            const participantsBySid = participantsRef.current[key];

            if (
              participantsBySid[0] &&
              participantsBySid[0].identity === userIdentity
            ) {
              updateParticipantStatus(participantsBySid[0], key, isOnline);
            }
          });
        }, 2500);
      }
    });

    client.on("participantJoined", (participant) => {
      handlePromiseRejection(
        () => handleParticipantsUpdate(participant, updateParticipants),
        addNotifications
      );
    });
    client.on("conversationUpdated", ({ conversation }) => {
      if (!cooldown.current) updateCurrentConversation(conversation.sid);
      handlePromiseRejection(() => {}, addNotifications);
    });

    client.on("messageUpdated", ({ message }) => {
      handlePromiseRejection(() => {}, addNotifications);
    });

    client.on("messageRemoved", (message) => {
      handlePromiseRejection(
        () => removeMessages(message.conversation.sid, [message]),
        addNotifications
      );
    });

    client.on("pushNotification", (event) => {
      // @ts-ignore
      if (event.type != "twilio.conversations.new_message") {
        return;
      }

      if (Notification.permission === "granted") {
        // showNotification(event);
      } else {
        console.log("Push notification is skipped", Notification.permission);
      }
    });

    client.on("tokenAboutToExpire", () => {
      if (username && password) {
        getToken().then((token) => {
          login(token);
        });
      }
    });

    client.on("initialized", () => {
      const { attributes, identity, isOnline, isNotifiable, isSubscribed } =
        client.user;
      const currentUser = {
        attributes,
        identity,
        isOnline,
        isNotifiable,
        isSubscribed,
      };
      // console.log("Client initialized with identity", currentUser);
      localStorage.setItem("username", client.user.identity);
      localStorage.setItem("currentUser", JSON.stringify(currentUser));
    });

    updateLoadingState(false);
    // getSubscribedConversations(client).then((conversations) => {
    //   addAllConversation(conversations);
    // });

    return () => {
      client?.removeAllListeners();
    };
  }, []);

  function addMessage(
    message: Message,
    addMessages: AddMessagesType,
    updateUnreadMessages: SetUnreadMessagesType
  ) {
    //transform the message and add it to redux
    handlePromiseRejection(() => {
      // if (sidRef.current === message.conversation.sid) {
      //   // message.conversation.updateLastReadMessageIndex(message.index);
      // }
      addMessages(message.conversation.sid, [message]);
      loadUnreadMessagesCount(message.conversation, updateUnreadMessages);
    }, addNotifications);
  }

  useEffect(() => {
    convoRef.current = conversations;
  }, [conversations]);

  useEffect(() => {
    const conversationInfo = {
      type: ActionType.UPDATE_UNREAD_MESSAGES,
      conversationCount: unread$.accumulator,
    };
    window.parent.postMessage(conversationInfo, "*");
  }, [unread$]);

  useEffect(() => {
    previousIndex.current = convoIndex;
  }, [convoIndex]);

  const openedConversation = useMemo(() => {
    return conversations[convoIndex];
  }, [convoIndex]);

  useEffect(() => {
    const accountType = localStorage.getItem("type");
    if (accountType === "company") {
      getSubscriptionInfo();
    }
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    const authToken = params.get("authToken");
    if (authToken) {
      localStorage.setItem("authToken", authToken);
    }
  }, []);

  return (
    <Box style={stylesheet.appWrapper}>
      <AlertsView />
      <Notifications />
      {}
      <Box style={stylesheet.appContainer(alertsExist)}>
        {((!openedConversation && isMobile) || !isMobile) && (
          <ConversationsContainer
            client={client}
            conversation={openedConversation}
            isMobile={isMobile}
          />
        )}
        {((openedConversation && isMobile) || !isMobile) && (
          <Box style={stylesheet.messagesWrapper}>
            <ConversationContainer
              conversation={openedConversation}
              client={client}
              sid={sid}
              isMobile={isMobile}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AppContainer;
