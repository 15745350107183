import { SubscriptionInformation, SubscriptionType } from ".";
import { ActionType } from "../action-types";
import { Action } from "../actions";

const initialState = {
  id: "",
  price: -1,
  type: "",
  subscriptionId: "",
  isActive: false,
};

const subscriptionReducer = (
  state: SubscriptionInformation = new SubscriptionInformation(
    "",
    -1,
    SubscriptionType.FREE,
    "",
    false
  ),
  action: Action
) => {
  switch (action.type) {
    case ActionType.GET_SUBSCRIPTION_INFO_SUCCESS:
      return action.payload;

    case ActionType.GET_SUBSCRIPTION_INFO_FAILURE:
      return action.payload;

    default:
      return {
        ...state,
      };
  }
};

export default subscriptionReducer;
