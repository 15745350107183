import { ActionType } from "../action-types";
import { Action } from "../actions";

const initialState: {
  unreadConvos: { sid: string; amount: number; sign: number }[];
  accumulator: number;
} = {
  unreadConvos: [],
  accumulator: 0,
};

const reducer = (
  state: {
    unreadConvos: { sid: string; amount: number }[];
    accumulator: number;
  } = initialState,
  action: Action
): {
  unreadConvos: { sid: string; amount: number }[];
  accumulator: number;
} => {
  switch (action.type) {
    case ActionType.INCREMENT_UNREAD_COUNT:
      const stateCopy = { ...state };
      const temp = stateCopy.unreadConvos.findIndex(
        (x) => x.sid === action.payload.sid
      );
      if (temp < 0) {
        stateCopy.unreadConvos.push({ sid: action.payload.sid, amount: 0 });
      }
      const idx = stateCopy.unreadConvos.findIndex(
        (c) => c.sid === action.payload.sid
      );
      const amount = action.payload.amount;
      stateCopy.unreadConvos[idx].amount += amount;

      if (stateCopy.unreadConvos[idx].amount < 0) {
        stateCopy.unreadConvos[idx].amount = 0;
      }
      stateCopy.accumulator += action.payload.amount;
      if (stateCopy.accumulator < 0) {
        stateCopy.accumulator = 0;
      }
      return stateCopy;
    default:
      return state;
  }
};

export default reducer;
