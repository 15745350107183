import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import ConversationView from "./ConversationView";
import {
  SetParticipantsType,
  SetSidType,
  SetUnreadMessagesType,
} from "../../types";
import { actionCreators, AppState } from "../../store";
import { getTypingMessage, unexpectedErrorNotification } from "../../helpers";
import { UNEXPECTED_ERROR_MESSAGE } from "../../constants";
import {
  ReduxConversation,
  convoSorter,
} from "../../store/reducers/convoReducer";
import { getSdkConversationObject } from "../../conversations-objects";
import { ReduxMessage } from "../../store/reducers/messageListReducer";
import { ParticipantUser } from "../../store/reducers/participantsReducer";
import { useEffect, useRef, useState } from "react";
import { Box } from "@twilio-paste/core";
import styles from "../../styles";
import { List, ListRowProps, ListRowRenderer } from "react-virtualized";

function getLastMessage(messages: ReduxMessage[], typingData: string[]) {
  if (messages === undefined || messages === null) {
    return "Loading...";
  }
  if (typingData.length) {
    return getTypingMessage(typingData);
  }
  if (messages.length === 0) {
    return "No messages";
  }
  return messages[messages.length - 1].body || "Media message";
}

function isMyMessage(messages: ReduxMessage[]) {
  if (messages === undefined || messages === null || messages.length === 0) {
    return false;
  }
  return messages[messages.length - 1].author ===
    localStorage.getItem("username")
    ? messages[messages.length - 1]
    : false;
}

async function updateCurrentConvo(
  setSid: SetSidType,
  convo: ReduxConversation,
  updateParticipants: SetParticipantsType
) {
  setSid(convo.sid);

  try {
    const participants = (
      await getSdkConversationObject(convo).getParticipants()
    ).filter((p) => p.identity !== localStorage.getItem("username"));
    const participantUsers: ParticipantUser[] = [];
    for (const participant of participants) {
      const user = await participant.getUser();
      participantUsers.push({ participant, user });
    }
    updateParticipants(participantUsers, convo.sid);
  } catch {
    return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
  }
}

const ConversationsList: React.FC = () => {
  const sid = useSelector((state: AppState) => state.sid);
  const conversations = useSelector((state: AppState) => state.convos);
  const messages = useSelector((state: AppState) => state.messages);
  const participants = useSelector((state: AppState) => state.participants);
  const typingData = useSelector((state: AppState) => state.typingData);

  const containerRef = useRef<HTMLDivElement>(null);
  const [chunkSize, setChunkSize] = useState(15);

  const dispatch = useDispatch();
  const {
    updateCurrentConversation,
    updateUnreadMessages,
    setLastReadIndex,
    addNotifications,
    incrementUnreadCount,
  } = bindActionCreators(actionCreators, dispatch);

  if (conversations === undefined || conversations === null) {
    return <div className="empty" />;
  }

  const conversationsToRender = conversations.map((convo) => (
    <ConversationView
      key={convo.sid}
      convoId={convo.sid}
      setSid={updateCurrentConversation}
      currentConvoSid={sid}
      conversations={conversations}
      lastMessage={
        getLastMessage(messages[convo.sid], typingData[convo.sid] ?? []) ?? ""
      }
      messages={messages[convo.sid]}
      typingInfo={typingData[convo.sid] ?? []}
      myMessage={isMyMessage(messages[convo.sid])}
      updateUnreadMessages={updateUnreadMessages}
      participants={participants[convo.sid] ?? []}
      allParticipants={participants}
      convo={convo}
      onClick={async () => {
        try {
          setLastReadIndex(convo.lastReadMessageIndex ?? -1);
          const { workerProfileId } =
            participants[convo.sid].length &&
            participants[convo.sid][0]?.attributes;

          window.parent.postMessage(
            {
              type: "open_conversation",
              body: { workerProfileId },
            },
            "*"
          );
          await updateCurrentConvo(
            updateCurrentConversation,
            convo,
            // updateParticipants
            () => null
          );
          //update unread messages
          updateUnreadMessages(convo.sid, 0);
          //set messages to be read
          const lastMessage =
            messages[convo.sid].length &&
            messages[convo.sid][messages[convo.sid].length - 1];
          if (lastMessage && lastMessage.index !== -1) {
            const sdkConversationObject = await getSdkConversationObject(convo);
            sdkConversationObject.updateLastReadMessageIndex(lastMessage.index);
          }
        } catch {
          unexpectedErrorNotification(addNotifications);
        }
      }}
    />
  ));

  const rowRenderer: ListRowRenderer = ({ index, key, style }) => {
    return (
      <div key={key} style={style}>
        {conversationsToRender[index]}
      </div>
    );
  };

  return (
    <Box ref={containerRef} style={styles.convoList}>
      <div
        id="conversation-list"
        style={{ overflowY: "scroll", overflowX: "hidden", marginRight: -10 }}
      >
        <List
          width={310}
          height={630}
          rowCount={conversationsToRender.length}
          rowHeight={76}
          rowRenderer={rowRenderer}
        />
      </div>
    </Box>
  );
};

export default ConversationsList;
