import { Avatar, Box } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";

import { ReduxConversation } from "../../store/reducers/convoReducer";
import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import Back from "../icons/Back";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

interface ConversationDetailsProps {
  convoSid: string;
  participants: ReduxParticipant[];
  convo: ReduxConversation;
  sid?: string;
  updateCurrentConversation: any;
  isMobile: boolean;
  setCurrentConversationIndex: any;
}

const ConversationDetails: React.FC<ConversationDetailsProps> = (
  props: ConversationDetailsProps
) => {
  const theme = useTheme();

  const convNameString = props.participants[0]?.friendlyName || "";
  const brandName =
    (props.participants[0] && props.participants[0].attributes.brandName) || "";
  const isSupportChat = props.convo.attributes.isSupportChat ?? false;
  const [isMobile, setIsMobile] = useState<boolean>(false);
  let profilePicture = undefined;
  const { participants } = props;
  if (participants[0]?.attributes?.image?.indexOf("http") >= 0) {
    profilePicture = participants[0]?.attributes?.image;
  }

  const convoIndex = useSelector(
    (state: AppState) => state.currentConversationIndex
  );
  const conversation = useSelector((state: AppState) => state.convos)[
    convoIndex
  ];
  const parts = useSelector((state: AppState) => state.participants);

  const workerProfileId =
    parts[conversation.sid] &&
    parts[conversation.sid].length &&
    parts[conversation.sid][0].attributes
      ? parts[conversation.sid][0].attributes.workerProfileId
      : "";

  const accountType = localStorage.getItem("type");
  const canDisplayAssignButton =
    accountType !== "support" && accountType === "company";

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;
    setIsMobile(isMobile);
  }, []);

  return (
    <Box
      style={{
        height: props.isMobile ? 130 : 65,
        paddingLeft: 16,
        paddingRight: 16,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: theme.borderColors.colorBorderWeak,
        paddingBottom: props.isMobile ? 65 : 0,
      }}
    >
      <Box
        style={{
          height: 65,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexGrow: 1,
            justifyContent: "end",
          }}
          color="colorText"
          fontFamily="fontFamilyText"
          fontSize="fontSize50"
          lineHeight="lineHeight80"
          fontWeight="fontWeightBold"
          maxHeight="100%"
        >
          {props.isMobile && (
            <Box
              paddingTop="space30"
              style={{
                paddingLeft: 10,
                paddingRight: 20,
                paddingBottom: 10,
                cursor: "pointer",
                position: "absolute",
              }}
              onClick={() => {
                props.updateCurrentConversation("");
                props.setCurrentConversationIndex(-1);
                window.parent.postMessage(
                  {
                    type: "close_conversation",
                    body: null,
                  },
                  "*"
                );
              }}
            ></Box>
          )}
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              justifyContent: !isMobile ? "space-between" : "",
            }}
          >
            {isMobile && <Back />}

            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar
                size="sizeIcon80"
                name={convNameString}
                color="decorative20"
                src={profilePicture}
              />
              <div style={{ marginLeft: 10 }}>
                {convNameString ?? "Unknown user"}
              </div>
            </div>
            {!isSupportChat && canDisplayAssignButton && workerProfileId && (
              <button
                style={{
                  backgroundColor: "#0f265c",
                  borderColor: "unset",
                  color: "#fff",
                  borderRadius: "25px",
                  opacity: "1",
                  textAlign: "center",
                  fontStyle: "normal",
                  fontVariant: "normal",
                  fontWeight: "normal",
                  fontSize: "1rem",
                  lineHeight: "25px",
                  letterSpacing: "0",
                  boxShadow: "none",
                  height: "-moz-fit-content",
                  maxHeight: isMobile ? "30%" : "fit-content",
                  minHeight: isMobile ? "30%" : "fit-content",
                  fontFamily: "Source Sans Pro",
                  paddingLeft: "13px",
                  paddingRight: "13px",
                }}
                onClick={() => {
                  window.parent.postMessage(
                    {
                      type: "go_to_assignment",
                      body: { workerProfileId },
                    },
                    "*"
                  );
                }}
              >
                Start new assignment
              </button>
            )}
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default ConversationDetails;
