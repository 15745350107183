import axios from "axios";
import { getBaseUrl } from "./enviroment";

// Create an instance of Axios
const axiosInstance = axios.create({
  baseURL: `${getBaseUrl()}/api`,
});

// Add a request interceptor to include the JWT token
axiosInstance.interceptors.request.use(
  (config) => {
    // Get the token from local storage or any other storage
    const token = localStorage.getItem("authToken");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token.replace(/['"]+/g, "")}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
