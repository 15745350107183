import { conversationsMap } from "../../conversations-objects";
import { ActionType } from "../action-types";
import { Action } from "../actions";

export type ReduxConversation = {
  sid: string;
  friendlyName: string | null;
  dateUpdated: Date | null;
  notificationLevel: "default" | "muted";
  lastReadMessageIndex: number | null;
  attributes: any;
  lastMessage?: {
    index?: number;
    dateCreated?: Date;
  };
};

const initialState: ReduxConversation[] = [];

export const convoSorter = (a: ReduxConversation, b: ReduxConversation) => {
  // Convert dates to milliseconds since epoch for comparison
  const dateA = new Date(
    a.lastMessage?.dateCreated ?? a.dateUpdated ?? new Date()
  ).getTime();
  const dateB = new Date(
    b.lastMessage?.dateCreated ?? b.dateUpdated ?? new Date()
  ).getTime();

  // Return the difference between the two dates
  return dateB - dateA;
};

const reducer = (
  state: ReduxConversation[] = initialState,
  action: Action
): ReduxConversation[] => {
  switch (action.type) {
    case ActionType.ADD_ALL_CONVERSATIONS:
      const conversations = action.payload;
      const reduxConversations = conversations.map((convo) => {
        conversationsMap.set(convo.sid, convo);
        const obj = {
          sid: convo.sid,
          friendlyName: convo.friendlyName,
          dateUpdated: convo.dateUpdated,
          notificationLevel: convo.notificationLevel,
          lastReadMessageIndex: convo.lastReadMessageIndex,
          attributes: convo.attributes,
          lastMessage: convo.lastMessage,
        };

        return obj;
      });
      return reduxConversations.sort(convoSorter);
    case ActionType.ADD_CONVERSATION:
      const {
        sid,
        friendlyName,
        dateUpdated,
        notificationLevel,
        lastReadMessageIndex,
        lastMessage,
        attributes,
      } = action.payload;
      const filteredClone = state.filter(
        (conversation) => conversation.sid !== action.payload.sid
      );

      conversationsMap.set(action.payload.sid, action.payload);
      return [
        ...filteredClone,
        {
          sid,
          attributes,
          friendlyName,
          dateUpdated,
          notificationLevel,
          lastReadMessageIndex,
          lastMessage: {
            ...lastMessage,
          },
        },
      ].sort(convoSorter);
    case ActionType.UPDATE_CONVERSATION: {
      const stateCopy = [...state];
      const target = stateCopy.find(
        (convo: ReduxConversation) => convo.sid === action.payload.channelSid
      );

      if (target) {
        Object.assign(target, {
          ...action.payload.parameters,
        });
      }

      return stateCopy.sort(convoSorter);
    }
    case ActionType.REMOVE_CONVERSATION: {
      const stateCopy = [...state];

      conversationsMap.delete(action.payload);

      return stateCopy.filter(
        (convo: ReduxConversation) => convo.sid !== action.payload
      );
    }

    case ActionType.SORT_CONVERSATIONS: {
      const stateCopy = [...state];
      const sid = action.payload;
      const convoToUpdate = stateCopy.find((c) => c.sid === sid);
      if (convoToUpdate && convoToUpdate.lastMessage) {
        convoToUpdate.lastMessage.dateCreated = new Date();
      } else if (convoToUpdate && !convoToUpdate.lastMessage) {
        convoToUpdate.lastMessage = {
          index: 0,
          dateCreated: new Date(),
        };
      }

      return stateCopy.sort(convoSorter);
    }

    default:
      return state;
  }
};

export default reducer;
