import React, { CSSProperties } from "react";
import { setCurrentConversationIndex } from "../../store/action-creators";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";
import { useDispatch } from "react-redux";

const Read: React.FC<{ color?: string; style?: CSSProperties }> = ({}) => {
  const dispatch = useDispatch();
  const { setCurrentConversationIndex } = bindActionCreators(
    actionCreators,
    dispatch
  );
  return (
    <div
      onClick={() => {
        window.parent.postMessage({ type: "remove_conversation" }, "*");

        setCurrentConversationIndex(-1);
      }}
      style={{
        backgroundColor: "#fff",
        height: 37,
        width: 37,
        display: "flex",
        borderRadius: "50%",
        boxShadow: "1px 3px 7px #0f265c38",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 22,
        opacity: 1,
        color: "#0f265c",
        cursor: "pointer",
      }}
    >
      <svg
        role="img"
        aria-hidden="true"
        focusable="false"
        data-prefix="fal"
        data-icon="chevron-left"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
        width={12}
      >
        <path
          fill="currentColor"
          d="M234.8 36.25c3.438 3.141 5.156 7.438 5.156 11.75c0 3.891-1.406 7.781-4.25 10.86L53.77 256l181.1 197.1c6 6.5 5.625 16.64-.9062 22.61c-6.5 6-16.59 5.594-22.59-.8906l-192-208c-5.688-6.156-5.688-15.56 0-21.72l192-208C218.2 30.66 228.3 30.25 234.8 36.25z"
        ></path>
      </svg>
    </div>
  );
};

export default Read;
