import React, { useState } from "react";
import { Client } from "@twilio/conversations";
import { Box } from "@twilio-paste/core";

import ConversationsList from "./ConversationsList";
import styles from "../../styles";
import { ReduxConversation } from "../../store/reducers/convoReducer";

interface ConvosContainerProps {
  client?: Client;
  conversation?: ReduxConversation;
  isMobile: boolean;
}

const ConversationsContainer: React.FC<ConvosContainerProps> = (
  props: ConvosContainerProps
) => {
  const [listHidden] = useState(false);

  return (
    <Box
      style={
        props.isMobile
          ? { ...styles.convosWrapper, ...styles.collapsedList }
          : styles.convosWrapper
      }
    >
      {!listHidden ? <ConversationsList /> : null}
    </Box>
  );
};

export default ConversationsContainer;
